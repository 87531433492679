export default {
    data() {
        return {
            natureDegatsConst: [
                {label: this.$t('nature_degats.glissieres'), value: 'GLISSIERES'},
                {label: this.$t('nature_degats.portique'), value: 'PORTIQUE'},
                {label: this.$t('nature_degats.enrobe'), value: 'ENROBE'},
                {label: this.$t('nature_degats.signalisation_verticale'), value: 'SIGNALISATION_VERTICALE'},
                {label: this.$t('nature_degats.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForNatureDegatsConst(value) {
            let result = this.natureDegatsConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getNatureDegatsConst() {
            return this.natureDegatsConst;
        }
    }
}