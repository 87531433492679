export default {
    data() {
        return {
            genreVehiculeConst: [
                {label: this.$t('genre_vehicule.vl'), value: 'VL'},
                {label: this.$t('genre_vehicule.fourgon'), value: 'FOURGON'},
                {label: this.$t('genre_vehicule.pl'), value: 'PL'},
                {label: this.$t('genre_vehicule.moto_scooter'), value: 'MOTO_SCOOTER'},
                {label: this.$t('genre_vehicule.velo'), value: 'VELO'},
                {label: this.$t('genre_vehicule.trotinette'), value: 'TROTINETTE'},
                {label: this.$t('genre_vehicule.autre_vehicule'), value: 'AUTRE_VEHICULE'},
            ],
        }
    },
    methods: {
        displayForGenreVehiculeConst(value) {
            let result = this.genreVehiculeConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getGenreVehiculeConst() {
            return this.genreVehiculeConst;
        }
    }
}