export default {
    data() {
        return {
            conditionMeteoConst: [
                {label: this.$t('condition_meteo.ras'), value: 'RAS'},
                {label: this.$t('condition_meteo.pluie'), value: 'PLUIE'},
                {label: this.$t('condition_meteo.neige'), value: 'NEIGE'},
                {label: this.$t('condition_meteo.brouillard'), value: 'BROUILLARD'},
            ],
        }
    },
    methods: {
        displayForConditionMeteoConst(value) {
            let result = this.conditionMeteoConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getConditionMeteoConst() {
            return this.conditionMeteoConst;
        }
    }
}