import Api from "@/services/api";

export default {
    findVehiculeImpliqueById(vehiculeImpliqueId) {
        return Api().get("/vehiculeImplique/" + vehiculeImpliqueId).then(res => {
            return this.formatRes(res.data);
        });
    },
    findVehiculesImpliquesByInterventionId(interventionId) {
        return Api().get("/vehiculeImplique/intervention/" + interventionId).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    createVehiculeImplique(vehiculeImplique) {
        return Api().post("/vehiculeImplique", vehiculeImplique).then(res => {
            return this.formatRes(res.data);
        });
    },
    updateVehiculeImplique(vehiculeImplique) {
        return Api().post("/vehiculeImplique", vehiculeImplique).then(res => {
            return this.formatRes(res.data);
        });
    },
    deleteVehiculeImplique(vehiculeImpliqueId) {
        return Api().delete("/vehiculeImplique/" + vehiculeImpliqueId).then(res => {
            return this.formatRes(res.data);
        });
    },
    updateOrCreateVehiculeImpliqueList(vehiculeImpliqueList) {
        return Api().post("/vehiculeImplique/updateorcreate", vehiculeImpliqueList).then(res => {
            return this.formatRes(res.data);
        });
    },
    formatRes(e) {
        return e;
    }
}