import Api from "@/services/api";

export default {
    findDdpById(ddpId) {
        return Api().get("/ddp/" + ddpId).then(res => {
            return this.formatRes(res.data);
        });
    },
    findDdpByInterventionId(interventionId) {
        return Api().get("/ddp/intervention/" + interventionId).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    createDdp(ddp) {
        return Api().post("/ddp", ddp).then(res => {
            return this.formatRes(res.data);
        });
    },
    updateDdp(ddp) {
        return Api().post("/ddp", ddp).then(res => {
            return this.formatRes(res.data);
        });
    },
    deleteDdp(ddpId) {
        return Api().delete("/ddp/" + ddpId).then(res => {
            return this.formatRes(res.data);
        });
    },
    updateOrCreateDdpList(ddpList) {
        return Api().post("/ddp/updateorcreate", ddpList).then(res => {
            return this.formatRes(res.data);
        });
    },
    formatRes(e) {
        return e;
    }
}