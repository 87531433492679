export default {
    data() {
        return {
            moyensHumainsConst: [
                {label: this.$t('moyens_humains.chef_equipe'), value: 'CHEF_EQUIPE'},
                {label: this.$t('moyens_humains.agent_exploitation'), value: 'AGENT_EXPLOITATION'},
            ],
        }
    },
    methods: {
        displayForMoyensHumainsConst(value) {
            let result = this.moyensHumainsConst.find(el => el.value === value);
            return result ? result.label : '';
        }
    }
}