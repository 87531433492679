export default {
    data() {
        return {
            moyensMaterielsConst: [
                {label: this.$t('moyens_materiels.vl'), value: 'VL'},
                {label: this.$t('moyens_materiels.vul'), value: 'VUL'},
                {label: this.$t('moyens_materiels.fourgons'), value: 'FOURGONS'},
                {label: this.$t('moyens_materiels.trafic_partner'), value: 'TRAFIC_PARTNER'},
                {label: this.$t('moyens_materiels.flr'), value: 'FLR'},
                {label: this.$t('moyens_materiels.sac_seau_absorbant'), value: 'SAC_SEAU_ABSORBANT'},
            ],
        }
    },
    methods: {
        displayForMoyensMaterielsConst(value) {
            let result = this.moyensMaterielsConst.find(el => el.value === value);
            return result ? result.label : '';
        }
    }
}