export default {
    data() {
        return {
            actionConst: [
                {label: this.$t('action.balisage'), value: 'BALISAGE'},
                {label: this.$t('action.neutralisation_voie_lente'), value: 'NEUTRALISATION_VOIE_LENTE'},
                {label: this.$t('action.neutralisation_voie_mediane'), value: 'NEUTRALISATION_VOIE_MEDIANE'},
                {label: this.$t('action.neutralisation_voie_rapide'), value: 'NEUTRALISATION_VOIE_RAPIDE'},
                {label: this.$t('action.fermeture_axe'), value: 'FERMETURE_AXE'},
                {label: this.$t('action.deviation'), value: 'DEVIATION'},
                {label: this.$t('action.nettoyage_balayage_ramassage'), value: 'NETTOYAGE_BALAYAGE_RAMASSAGE'},
                {label: this.$t('action.protection'), value: 'PROTECTION'},
                {label: this.$t('action.fermeture_bretelle'), value: 'FERMETURE_BRETELLE'},
                {label: this.$t('action.autre'), value: 'AUTRE'},
            ],
        }
    },
    methods: {
        displayForActionConst(value) {
            let result = this.actionConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getActionConst() {
            return this.actionConst;
        }
    }
}