export default {
    data() {
        return {
            intervenantConst: [
                {label: this.$t('intervenant.police'), value: 'POLICE'},
                {label: this.$t('intervenant.gendarmerie'), value: 'GENDARMERIE'},
                {label: this.$t('intervenant.pompiers'), value: 'POMPIERS'},
                {label: this.$t('intervenant.depanneurs'), value: 'DEPANNEURS'},
                {label: this.$t('intervenant.samu_smur'), value: 'SAMU_SMUR'},
                {label: this.$t('intervenant.autres'), value: 'AUTRES'},
            ],
        }
    },
    methods: {
        displayForIntervenantConst(value) {
            let result = this.intervenantConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getIntervenantConst() {
            return this.intervenantConst;
        }
    }
}