export default {
    data() {
        return {
            etatChausseeConst: [
                {label: this.$t('etat_chaussee.seche'), value: 'SECHE'},
                {label: this.$t('etat_chaussee.humide'), value: 'HUMIDE'},
                {label: this.$t('etat_chaussee.mouillee'), value: 'MOUILLEE'},
                {label: this.$t('etat_chaussee.ruisselante'), value: 'RUISSELANTE'},
                {label: this.$t('etat_chaussee.verglacee'), value: 'VERGLACEE'},
                {label: this.$t('etat_chaussee.enneigee'), value: 'ENNEIGEE'},
            ],
        }
    },
    methods: {
        displayForEtatChausseeConst(value) {
            let result = this.etatChausseeConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getEtatChausseeConst() {
            return this.etatChausseeConst;
        }
    }
}