export default {
    data() {
        return {
            vehiculeImpliqueConst: [
                {label: this.$t('vehicules_impliques.vl'), value: 'VL'},
                {label: this.$t('vehicules_impliques.fourgon'), value: 'FOURGON'},
                {label: this.$t('vehicules_impliques.pl'), value: 'PL'},
                {label: this.$t('vehicules_impliques.moto_scooter'), value: 'MOTO_SCOOTER'},
                {label: this.$t('vehicules_impliques.velo'), value: 'VELO'},
                {label: this.$t('vehicules_impliques.trotinette'), value: 'TROTINETTE'},
                {label: this.$t('vehicules_impliques.autre_vehicule'), value: 'AUTRE_VEHICULE'},
            ],
        }
    },
    methods: {
        displayForVehiculeImpliqueConst(value) {
            let result = this.vehiculeImpliqueConst.find(el => el.value === value);
            return result ? result.label : '';
        },
        getVehiculeImpliqueConst() {
            return this.vehiculeImpliqueConst;
        }
    }
}